import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import axios from '../../utils/axios';
import { history } from '../../routers/AppRouter';


const initialState = {
  profile: {},
  tenants: [],
  tenant: {},
  loading: false,
  isEsignReady: false,
  status: [],
  messages: [],
};

const slice = createSlice({
  name: 'landlordApplication',
  initialState,
  reducers: {
    landlordApplicationBegin: (landlordApplication) => {
      landlordApplication.loading = true;
    },
    landlordApplicationEnd: (landlordApplication) => {
      landlordApplication.loading = false;
    },

    /// LANDLORD PROFILE
    getLandlordProfile: (landlordApplication, action) => {
      landlordApplication.loading = false;
      landlordApplication.profile = action.payload;
    },
    updateLandlordProfile: (landlordApplication) => {
      landlordApplication.loading = false;
    },
    signLandlord: (landlordApplication) => {
      landlordApplication.loading = false;
    },

    /// LANDLORD PROPERTIES TENANTS
    getRentalPropertyTenants: (landlordApplication, action) => {
      landlordApplication.tenants = action.payload;
      landlordApplication.loading = false;
    },
    getRentalPropertyTenant: (landlordApplication, action) => {
      landlordApplication.tenant = action.payload;
      landlordApplication.loading = false;
    },
    addRentalPropertyTenant: (landlordApplication) => {
      landlordApplication.loading = false;
    },
    updateRentalPropertyTenant: (landlordApplication) => {
      landlordApplication.loading = false;
    },
    deleteRentalPropertyTenant: (landlordApplication) => {
      landlordApplication.loading = false;
    },

    // GET RENTAL PROPERTY TENANT STATUS
    getStatus: (landlordApplication, action) => {
      landlordApplication.status = action.payload;
      landlordApplication.loading = false;
    },

    // MESSAGE
    getMessages: (landlordApplication, action) => {
      landlordApplication.messages = action.payload;
      landlordApplication.loading = false;
    },
    sendMessage: (landlordApplication) => {
      landlordApplication.loading = false;
    },
    updateMessage: (landlordApplication) => {
      landlordApplication.loading = false;
    },

    // SET E-SIGN READY
    setEsignReady: (landlordApplication, action) => {
      landlordApplication.isEsignReady = action.payload;
      landlordApplication.loading = false;
    },
  },
});

/// ///////////////////////////////////
/// ////// LANDLORD PROFILE //////////
/// /////////////////////////////////

// GET_LANDLORD_PROFILE
export const startGetLandlordProfile = () => async (dispatch, getState) => {
  dispatch(landlordApplicationBegin());
  try {
    const response = await axios.get(`${baseURL}/user/landlordProfile`);
    dispatch(getLandlordProfile(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(landlordApplicationEnd());
  }
};

// UPDATE_LANDLORD_PROFILE
export const startUpdateLandlordProfile = (payload) => async (dispatch, getState) => {
  dispatch(landlordApplicationBegin());
  try {
    const response = await axios.put(`${baseURL}/user/landlordProfile`, payload);
    dispatch(updateLandlordProfile());
    toast.success('Profile successfully Updated!');
    history.push('/application/landlord');
  } catch (error) {
    console.log(error.response);
    dispatch(landlordApplicationEnd());
  }
};

// SIGN_LANDLORD
export const startAddLandLordSignature = (payload, setSubmitting) => async (dispatch, getState) => {
  dispatch(landlordApplicationBegin());
  try {
    const response = await axios.post(`${baseURL}/signature/landlord`, payload);
    dispatch(signLandlord());
    toast.success('Signed!');
    history.push('/application/landlord');
  } catch (error) {
    setSubmitting();
    console.log(error.response);
    dispatch(landlordApplicationEnd());
  }
};

/// ///////////////////////////////////////////
/// ////// LANDLORD PROPERTY TENANTS /////////
/// /////////////////////////////////////////

// GET_RENTAL_PROPERTY_TENANTS
export const startGetRentalPropertyTenants = () => async (dispatch, getState) => {
  dispatch(landlordApplicationBegin());
  try {
    const response = await axios.get(`${baseURL}/rentalPropertyTenants`);
    dispatch(getRentalPropertyTenants(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(landlordApplicationEnd());
  }
};

// GET_RENTAL_PROPERTY_TENANT
export const startGetRentalPropertyTenant = (rentalPropertyTenantID) => async (dispatch, getState) => {
  dispatch(landlordApplicationBegin());
  try {
    const response = await axios.get(`${baseURL}/rentalPropertyTenants/${rentalPropertyTenantID}`);
    dispatch(getRentalPropertyTenant(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(landlordApplicationEnd());
  }
};

// ADD_RENTAL_PROPERTY_TENANT
export const startAddRentalPropertyTenant = (payload, setSubmitting) => async (dispatch, getState) => {
  dispatch(landlordApplicationBegin());
  try {
    const response = await axios.post(`${baseURL}/rentalPropertyTenants`, payload);
    toast.success('Tenant Created');
    history.push('/application/landlord');
  } catch (error) {
    if (error.response.data.message === 'OUT_OF_BOUNDS') {
      toast.warning('The tenant\'s zip code is outside of the program\'s reach');
    }
    setSubmitting();
    dispatch(landlordApplicationEnd());
  }
};

// UPDATE_RENTAL_PROPERTY_TENANT
export const startUpdateRentalPropertyTenant = (payload, setSubmitting, tenantID) => async (dispatch, getState) => {
  dispatch(landlordApplicationBegin());
  try {
    const response = await axios.put(`${baseURL}/rentalPropertyTenants/${tenantID}`, payload);
    toast.success('Tenant Updated');
    history.push('/application/landlord');
  } catch (error) {
    if (error.response.data.message === 'OUT_OF_BOUNDS') {
      toast.warning('The tenant\'s zip code is outside of the program\'s reach');
    }
    console.log(error.response);
    setSubmitting();
    dispatch(landlordApplicationEnd());
  }
};

// DELETE_RENTAL_PROPERTY_TENANT
export const startDeleteRentalPropertyTenant = (rentalPropertyTenantID, toggle) => async (dispatch, getState) => {
  dispatch(landlordApplicationBegin());
  try {
    const response = await axios.delete(`${baseURL}/rentalPropertyTenants/${rentalPropertyTenantID}`);
    dispatch(deleteRentalPropertyTenant(response.data));
    toggle();
    toast.warning('Tenant Deleted');
    dispatch(startGetRentalPropertyTenants());
  } catch (error) {
    console.log(error.response);
    dispatch(landlordApplicationEnd());
  }
};

/// ////////////////////////////////////
/// ////// APPLICATION STATUS //////////
/// ////////////////////////////////////

// GET_RENTAL_PROPERTY_TENANT_STATUS

export const startGetStatus = () => async (dispatch, getState) => {
  dispatch(landlordApplicationBegin());
  try {
    const response = await axios.get(`${baseURL}/landlord/status`);
    dispatch(getStatus(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(landlordApplicationEnd());
  }
};

// ////////////////////////////
// /////// MESSAGES //////////
// ///////////////////////////

// GET_MESSAGES
export const startGetMessages = () => async (dispatch, getState) => {
  dispatch(landlordApplicationBegin());
  try {
    const response = await axios.get(`${baseURL}/messages`);
    dispatch(getMessages(response.data.conversation));
  } catch (error) {
    console.log(error.response);
    dispatch(landlordApplicationEnd());
  }
};

// SEND_MESSAGE
export const startSendMessage = (payload, setSubmitting, resetForm) => async (dispatch, getState) => {
  dispatch(landlordApplicationBegin());
  try {
    const response = await axios.post(`${baseURL}/messages`, payload);
    dispatch(sendMessage());
    dispatch(startGetMessages());
    toast.success('Sent!');
    setSubmitting();
    resetForm({
      values: {
        text: '',
      },
    });
    dispatch(startUpdateMessage());
  } catch (error) {
    console.log(error.response);
    setSubmitting();
    dispatch(landlordApplicationEnd());
  }
};

// UPDATE_MESSAGE
export const startUpdateMessage = () => async (dispatch, getState) => {
  dispatch(landlordApplicationBegin());
  try {
    await dispatch(startGetMessages());
    const { messages } = getState().landlordApplication;
    const messageIDs = messages.filter((f) => f.type === 'admin').map((d) => d.messageID);
    const payload = {
      messageIDs,
    };
    const response = await axios.put(`${baseURL}/messages/read`, payload);
    dispatch(updateMessage());
    dispatch(startGetMessages());
  } catch (error) {
    console.log(error.response);
    dispatch(landlordApplicationEnd());
  }
};

export const {
  landlordApplicationBegin,
  landlordApplicationEnd,
  getLandlordProfile,
  updateLandlordProfile,
  signLandlord,
  getRentalPropertyTenants,
  getRentalPropertyTenant,
  updateRentalPropertyTenant,
  deleteRentalPropertyTenant,
  getStatus,
  getMessages,
  sendMessage,
  updateMessage,
  setEsignReady,
} = slice.actions;

export default slice.reducer;
