import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router';

const PublicRoute = ({ redirectPath = '/application', children }) => {
  const location = useLocation();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isLandlord = useSelector((state) => state.auth.currentUser.isLandlord);

  const type = isLandlord ? 'landlord' : 'tenant';

  if (isLoggedIn) return <Navigate to={`${redirectPath}/${type}`} replace from={{ from: location }}/>;
  return children;
};

PublicRoute.propTypes = {
  redirectPath: PropTypes.string,
  children: PropTypes.element,
};

export default PublicRoute;
