/* eslint-disable no-undef */
import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import { toast } from 'react-toastify';

// import PropTypes from 'prop-types';
import { Loading, NotFoundPage } from '../cdui';
import { startLogout } from '../redux/reducers/auth';

import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import {
  PublicRoute,
  CustomRouter,
  PassEligibleRoute,
  PrivateLandlordRoute,
  PrivateLandlordRPTenantRoute,
  PrivateTenantRoute,
  PrivateTenantEsignRoute,
  PrivateLandlordEsignRoute,
  lazyWithRetry,
  ScrollToTop,
  AppWrapper,
} from './helpers';


// LANDING PAGE
const LandingPage = lazyWithRetry(() => import('../components/landing/LandingPage'));

// AUTH
const SignUp = lazyWithRetry(() => import('../components/auth/SignUp'));
const LogIn = lazyWithRetry(() => import('../components/auth/LogIn'));
const ForgotPassword = lazyWithRetry(() => import('../components/auth/ForgotPassword'));
const ResetPasswordPage = lazyWithRetry(() => import('../components/auth/ResetPasswordPage'));

// PRESCREENING PAGE
const IndexPrescreening = lazyWithRetry(() => import('../components/prescreening/IndexPrescreening'));
const Ineligible = lazyWithRetry(() => import('../components/prescreening/Ineligible'));

// LANDLORD APPLICATION
const LandLordApplication = lazyWithRetry(() => import('../components/applications/LandLordApplication'));
const LandlordInformation = lazyWithRetry(() => import('../components/applications/landlord/LandlordInfo'));
const LandlordDocumentsUpload = lazyWithRetry(() => import('../components/applications/landlord/DocumentsUpload'));
const AddPropertyTenant = lazyWithRetry(() => import('../components/applications/landlord/AddPropertyTenant'));
// const PropertyTenantDocumentsUpload = lazyWithRetry(() => import('../components/applications/landlord/PropertyTenantDocumentsUpload'))
const EditPropertyTenant = lazyWithRetry(() => import('../components/applications/landlord/EditPropertyTenant'));
const LandlordESign = lazyWithRetry(() => import('../components/applications/landlord/ESign'));
const LandlordMessageCenter = lazyWithRetry(() => import('../components/applications/landlord/MessageCenter'));

// TENANT APPLICATION
const TenantApplication = lazyWithRetry(() => import('../components/applications/TenantApplication'));
const TenantLandlordInformation = lazyWithRetry(() => import('../components/applications/tenant/LandlordInfo'));
const HouseHoldInfo = lazyWithRetry(() => import('../components/applications/tenant/HouseHoldInfo'));
const FinancialInfo = lazyWithRetry(() => import('../components/applications/tenant/FinancialInfo'));
const AssistanceInfo = lazyWithRetry(() => import('../components/applications/tenant/AssistanceInfo'));
const LandlordSearchResult = lazyWithRetry(() => import('../components/applications/tenant/LandlordSearchResult'));
const ApplicantInformation = lazyWithRetry(() => import('../components/applications/tenant/ApplicantInfo'));
const TenantDocumentsUpload = lazyWithRetry(() => import('../components/applications/tenant/DocumentsUpload'));
const TenantESign = lazyWithRetry(() => import('../components/applications/tenant/ESign'));
const TenantMessageCenter = lazyWithRetry(() => import('../components/applications/tenant/MessageCenter'));

// STATIC
const FAQ = lazyWithRetry(() => import('../components/static/FAQ'));
const RequiredDocuments = lazyWithRetry(() => import('../components/static/RequiredDocuments'));
const ReportFraud = lazyWithRetry(() => import('../components/static/ReportFraud'));
const Resources = lazyWithRetry(() => import('../components/static/Resources'));


// TEST
const TestPage = lazyWithRetry(() => import('../components/TestPage'));

export const history = createBrowserHistory();

const AppRouter = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  const [isTranslatorOpened, setTranslatorOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const observer = new MutationObserver((m) => {
        const [mutation] = m;
        if (mutation && mutation.target.firstElementChild.className === 'skiptranslate') {
          mutation.target.firstElementChild.style.display === 'none'
            ? setTranslatorOpen(false)
            : setTranslatorOpen(true);
        }
      });

      const [target] = document.getElementsByClassName('sidebar-fixed');
      observer.observe(target, { attributes: true, attributeFilter: ['style'] });
    }, 1000);
  }, []);

  return (
    <CustomRouter history={history}>
      <ScrollToTop>
        <div className="container-scroller">
          <NavBar
            isTranslatorOpened={isTranslatorOpened}
            isLoggedIn={isLoggedIn}
            onClickLogIn={() => {
              history.push('/login');
            }}
            onClickLogOut={() => {
              dispatch(startLogout());
            }} />

          <AppWrapper>
            <Suspense fallback={<Loading main={true} />}>
              <Routes>
                {/* LANDING */}
                <Route path='/' element={<PublicRoute><LandingPage /></PublicRoute>} exact />

                {/* AUTH */}
                <Route path='/login' element={<PublicRoute><LogIn /></PublicRoute>} exact />
                <Route path='/forgot-password' element={<PublicRoute><ForgotPassword /></PublicRoute>} exact />
                <Route path='/reset-password/:token?' element={<PublicRoute><ResetPasswordPage /></PublicRoute>} exact />

                {/* PRESCREENING */}
                {ALLOW_SIGNUP === 'true' && <Route path='/check-eligibility/:type/:inviteCode?' element={<PublicRoute><IndexPrescreening /></PublicRoute>} exact />}
                {ALLOW_SIGNUP === 'true' && <Route path='/ineligible' element={<PublicRoute><Ineligible /></PublicRoute>} exact />}

                {/* SIGN_UP */}
                {ALLOW_SIGNUP === 'true' && <Route path='/signup/tenant/:inviteCode?' element={<PassEligibleRoute><SignUp /></PassEligibleRoute>} exact />}
                {ALLOW_SIGNUP === 'true' && <Route path='/signup/landlord/:inviteCode?' element={<PublicRoute><SignUp /></PublicRoute>} exact />}

                {/* APPLICATIONS */}
                <Route path='/application/landlord' element={<PrivateLandlordRoute><LandLordApplication /></PrivateLandlordRoute>} exact />
                <Route path='/application/landlord/info' element={<PrivateLandlordRoute><LandlordInformation /></PrivateLandlordRoute>} exact />
                <Route path='/application/landlord/documents' element={<PrivateLandlordRoute><LandlordDocumentsUpload /></PrivateLandlordRoute>} exact />

                <Route path='/application/landlord/tenant/new' element={<PrivateLandlordRPTenantRoute><AddPropertyTenant /></PrivateLandlordRPTenantRoute>} exact />
                <Route path='/application/landlord/tenant/:tenantID' element={<PrivateLandlordRPTenantRoute><EditPropertyTenant /></PrivateLandlordRPTenantRoute>} exact />
                <Route path='/application/landlord/e-sign' element={<PrivateLandlordEsignRoute><LandlordESign /></PrivateLandlordEsignRoute>} exact />
                <Route path='/application/landlord/message-center' element={<PrivateLandlordRoute><LandlordMessageCenter /></PrivateLandlordRoute>} exact />

                <Route path='/application/tenant' element={<PrivateTenantRoute><TenantApplication /></PrivateTenantRoute>} exact />
                <Route path='/application/tenant/info' element={<PrivateTenantRoute><ApplicantInformation /></PrivateTenantRoute>} exact />
                <Route path='/application/tenant/landlord' element={<PrivateTenantRoute><TenantLandlordInformation /></PrivateTenantRoute>} exact />
                <Route path='/application/tenant/landlord/result' element={<PrivateTenantRoute><LandlordSearchResult /></PrivateTenantRoute>} exact />
                <Route path='/application/tenant/household' element={<PrivateTenantRoute><HouseHoldInfo /></PrivateTenantRoute>} exact />
                <Route path='/application/tenant/financial' element={<PrivateTenantRoute><FinancialInfo /></PrivateTenantRoute>} exact />
                <Route path='/application/tenant/assistance' element={<PrivateTenantRoute><AssistanceInfo /></PrivateTenantRoute>} exact />
                <Route path='/application/tenant/documents' element={<PrivateTenantRoute><TenantDocumentsUpload /></PrivateTenantRoute>} exact />
                <Route path='/application/tenant/message-center' element={<PrivateTenantRoute><TenantMessageCenter /></PrivateTenantRoute>} exact />
                <Route path='/application/tenant/e-sign' element={<PrivateTenantEsignRoute><TenantESign /></PrivateTenantEsignRoute>} exact />

                {/* STATIC */}
                <Route path='/faq' element={<FAQ />} exact />
                <Route path='/required-documents' element={<RequiredDocuments />} exact />
                <Route path='/report-fraud' element={<ReportFraud />} exact />
                <Route path='/resources' element={<Resources />} exact />

                {/* TEST */}
                {environment === 'development' && <Route path='/test' element={<TestPage />} exact />}

                <Route path='*' element={<NotFoundPage onClick={() => {
                  history.push('/');
                }} />} />
              </Routes>
            </Suspense>

          </AppWrapper>
          <Footer />
          {/* PIXEL - 10/01/2024 - https://hotbsoftware.atlassian.net/browse/CDNE-733 */}
          {/* <!-- By using this audio pixel code, the Customer hereby instructs AudioGO toProcess Personal Data in its quality as a sub-processor. The ownership and control of Personal Data remains with Customer, and Customer will always remain the Data Controller. Customer is responsible for compliance with its obligations as Data Controller under the Data Protection Laws, in particular for justification of any transmission of Personal Data to AudioGO (including but not limited providing any required notices and obtaining any required consents from the data subjects), and for its decisions concerning the Processing and use of the Personal Data. --> */}
          {environment === 'production' && <img src='https://us-3575-adswizz.attribution.adswizz.com/fire?pixelId=e233577a-793c-4146-887f-7706438b0aff&type=sitevisit&subtype=HomePage&aw_0_req.gdpr=true&redirectURL=aHR0cHM6Ly9waXhlbC50YXBhZC5jb20vaWRzeW5jL2V4L3JlY2VpdmU_cGFydG5lcl9pZD0yOTk0JjwjaWYgcmVxdWVzdC5saXN0ZW5lcklkP21hdGNoZXMoJ1swLTlhLWZdezh9LVswLTlhLWZdezR9LVswLTlhLWZdezR9LVswLTlhLWZdezR9LVswLTlhLWZdezEyfScpPnBhcnRuZXJfdHlwZWRfZGlkPSU3QiUyMkhBUkRXQVJFX0FORFJPSURfQURfSUQlMjIlM0ElMjIke3JlcXVlc3QubGlzdGVuZXJJZH0lMjIlN0Q8I2Vsc2VpZiByZXF1ZXN0Lmxpc3RlbmVySWQ_bWF0Y2hlcygnWzAtOUEtRl17OH0tWzAtOUEtRl17NH0tWzAtOUEtRl17NH0tWzAtOUEtRl17NH0tWzAtOUEtRl17MTJ9Jyk-cGFydG5lcl90eXBlZF9kaWQ9JTdCJTIySEFSRFdBUkVfSURGQSUyMiUzQSUyMiR7cmVxdWVzdC5saXN0ZW5lcklkfSUyMiU3RDwjZWxzZT5wYXJ0bmVyX2RldmljZV9pZD0ke3JlcXVlc3QubGlzdGVuZXJJZCF9PC8jaWY-' height='0' width='0' style={{ display: 'none', visibility: 'hidden' }} />}
        </div>
      </ScrollToTop>
    </CustomRouter>
  );
};

AppRouter.propTypes = {
};

export default AppRouter;
