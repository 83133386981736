import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router';

const PrivateLandlordRoute = ({ redirectPath = '/login', children }) => {
  const location = useLocation();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isLandlord = useSelector((state) => state.auth.currentUser.isLandlord);

  if (!(isLoggedIn && isLandlord)) return <Navigate to={redirectPath} replace from={{ from: location }}/>;
  return children;
};

PrivateLandlordRoute.propTypes = {
  redirectPath: PropTypes.string,
  children: PropTypes.element,
};

export default PrivateLandlordRoute;
