import React from 'react';
import PropTypes from 'prop-types';

const AppWrapper = ({ children }) => (
  <div className={'app-wrapper'}>
    {children}
  </div>
);

AppWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

export default AppWrapper;
