import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import ls from 'local-storage';

import store from './redux/store';
import AppRouter from './routers/AppRouter';
import { login } from './redux/reducers/auth';

import 'react-toastify/dist/ReactToastify.css';
import './styles/styles.scss';
import { ToastContainer } from 'react-toastify';

import { ScrollToTopButton } from './cdui';

if (ls('user')) {
  const user = ls('user');
  console.log('User: ', user);
  store.dispatch(login(user));
}

if (ls('authorization')) {
  ls.remove('authorization');
}

const container = document.getElementById('app');
const root = createRoot(container);

root.render(<Provider store={store}>
  <ToastContainer
    position='top-right'
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnVisibilityChange
    draggable
    pauseOnHover />
  <AppRouter />
  <ScrollToTopButton handleClick={() => {
    window.scrollTo(0, 0);
  }} />
</Provider>);
