import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

import Button from '../Button';

const ContentModal = ({ isOpen, toggle, children, button, title, size = 'md', onClosed = () => { }, noCancel }) => (
  <div>
    <Modal isOpen={isOpen} toggle={toggle} size={size} onClosed={onClosed}>
      <ModalHeader className='p-0' toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        {children}
        {button}
        {!noCancel && (
          <div className='ps-1'>
            <Button block outline onClick={toggle} className='py-3 mb-2'>Cancel</Button>
          </div>
        )}
      </ModalBody>
    </Modal>
  </div>
);

ContentModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  button: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  title: PropTypes.string,
  headerColor: PropTypes.string,
  size: PropTypes.string,
  onClosed: PropTypes.func,
  noCancel: PropTypes.bool,
};

export default ContentModal;
