import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const FormError = ({ errors, submitCount, message }) => (
  <Fragment>
    {((Object.keys(errors).length > 0) && (submitCount > 0)) &&
      <div className='pb-2 pt-4 text-danger'>
        <span className='fw-bold'>
          <span className='fas fa-exclamation-triangle pe-2' />
          Something went wrong!
        </span>
        <span className='ps-2'>
          {message ? message : 'Check to see if you have correctly filled in all required fields.'}
        </span>
      </div>
    }
  </Fragment>
);

FormError.propTypes = {
  errors: PropTypes.object,
  submitCount: PropTypes.number,
  message: PropTypes.string,
};

export default FormError;
