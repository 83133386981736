import { lazy } from 'react';
import ls from 'local-storage';


const lazyWithRetry = (componentImport) => lazy(async () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(ls.get('page-has-been-force-refreshed') || 'false');

  try {
    const component = await componentImport();
    ls.set('page-has-been-force-refreshed', 'false');
    return component;
  } catch (error) {
    if (!pageHasAlreadyBeenForceRefreshed) {
      ls.set('page-has-been-force-refreshed', 'true');
      return window.location.reload();
    }
    throw error;
  }
});


export default lazyWithRetry;
