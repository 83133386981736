import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router';

const PrivateTenantEsignRoute = ({ redirectPath = '/login', children }) => {
  const location = useLocation();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isTenant = useSelector((state) => state.auth.currentUser.isTenant);
  const isEsignReady = useSelector((state) => state.tenantApplication.isEsignReady);

  if (!(isLoggedIn && isTenant && isEsignReady)) return <Navigate to={redirectPath} replace from={{ from: location }}/>;
  return children;
};

PrivateTenantEsignRoute.propTypes = {
  redirectPath: PropTypes.string,
  children: PropTypes.element,
};

export default PrivateTenantEsignRoute;
