import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';

const PrivateLandlordRPTenantRoute = ({ redirectPath = '/login', children }) => {
  const location = useLocation();
  const isLandlord = useSelector((state) => state.auth.currentUser.isLandlord);
  const profile = useSelector((state) => state.landlordApplication.profile);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const { isAllDocumentsUploaded, signatureID } = profile;
  const isEsignDone = profile && signatureID;
  const isDocumentsUploaded = profile && isAllDocumentsUploaded;

  if (!(isLoggedIn && isLandlord && isDocumentsUploaded && isEsignDone)) return <Navigate to={redirectPath} replace from={{ from: location }}/>;
  return children;
};

PrivateLandlordRPTenantRoute.propTypes = {
  redirectPath: PropTypes.string,
  children: PropTypes.element,
};

export default PrivateLandlordRPTenantRoute;
