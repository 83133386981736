import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

import Button from '../Button';

const DeleteModal = ({ isOpen, toggle, item, onClick, title }) => (
  <div>
    <Modal isOpen={isOpen} toggle={toggle} contentClassName='rounded' size='sm'>
      <ModalBody className='text-center'>
        <span className='fas fa-trash text-danger d-block mb-3' style={{ fontSize: '50px' }} />
        {title && <h4 className='text-center mb-4'>{title}</h4>}
        <p className='text-center mb-1'>Are you sure you want to delete: </p>
        <p className='text-center fw-bold' >{item}</p>
        <Button block variant='danger' className='text-white py-3 mt-4 mb-2' onClick={onClick}>Yes, Delete</Button>
        <Button block outline className='btn-white py-3' onClick={toggle}>Cancel</Button>
      </ModalBody>
    </Modal>
  </div>
);

DeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  item: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export default DeleteModal;
