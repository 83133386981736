import React from 'react';
import PropTypes from 'prop-types';


const Button = ({
  type = 'button',
  variant = 'primary',
  ariaLabel,
  outline = false,
  ghost = false,
  size = 'md',
  block = false,
  disabled = false,
  loading = false,
  className = '',
  onClick,
  children,
  notification = '',
  ref,
  uniqueID,
  ...other
}) => {
  let colorClass = 'btn-';
  if (outline) {
    colorClass += 'outline-';
  } else if (ghost) {
    colorClass += 'ghost-';
  }
  colorClass += variant;

  let badge = '';
  if (notification) badge = ' position-relative';
  const buttonContent = (
    <button
      ref={ref}
      type={type}
      className={`btn btn-${size} ${colorClass} ${className}${badge}`}
      disabled={loading || disabled}
      onClick={onClick}
      aria-label={ariaLabel}
      key={uniqueID}
      {...other}
    >
      {loading
        ? <span><span className='fas fa-spinner fa-spin me-2' />Loading...</span>
        : children
      }
      {notification &&
        <span className={`position-absolute top-0 start-100 translate-middle bg-danger ${notification.length >= 0 ? 'badge rounded-pill' : 'p-2 rounded-circle'}`}>
          {notification.length > 0 && notification}
        </span>
      }
    </button>
  );

  return block
    ? <div className='d-grid'>{buttonContent}</div>
    : buttonContent;
};

Button.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  ariaLabel: PropTypes.string,
  outline: PropTypes.bool,
  ghost: PropTypes.bool,
  size: PropTypes.string,
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any,
  notification: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  ref: PropTypes.any,
  uniqueID: PropTypes.string,
};

export default Button;
