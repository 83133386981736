import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

const initialState = {
  bannerText: '',
  loading: false,
  isUsingBannerText: '',
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    configBegin: (config) => {
      config.loading = true;
    },
    configEnd: (config) => {
      config.loading = false;
    },
    getBannerText: (config, action) => {
      config.loading = false;
      config.bannerText = action.payload.settingValue;
    },
    getIsUsingBannerText: (config, action) => {
      config.loading = false;
      config.isUsingBannerText = action.payload.settingValue;
    },
  },
});

// GET_CONFIG
export const startGetConfigs = (type) => async (dispatch, getState) => {
  dispatch(configBegin());
  try {
    const response = await axios.get(`${baseURL}/config/settings/${type}`);
    if (type === 'bannerText') {
      dispatch(getBannerText(response.data));
    }
    if (type === 'isUsingBannerText') {
      dispatch(getIsUsingBannerText(response.data));
    }
  } catch (error) {
    console.log(error.response);
    dispatch(configEnd());
  }
};

export const {
  configBegin,
  configEnd,
  getBannerText,
  getIsUsingBannerText,
} = slice.actions;

export default slice.reducer;
