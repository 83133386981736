import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startGetTemplateDocument } from '../redux/reducers/documents';
import GoogleTranslate from './GoogleTranslate';

const Footer = () => {
  const templateDocuments = useSelector((state) => state.documents.templateDocuments);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!Object.keys(templateDocuments).includes('PRVC')) {
      dispatch(startGetTemplateDocument('PRVC'));
    }
  }, []);

  return (
    <footer className='footer p-0'>
      <div className='row px-3 px-lg-5 py-3 py-lg-0'>
        <div className='col-12 col-md-3 py-0 py-lg-3 pe-3'>
          <div className='pt-3 pb-3 pb-lg-0 px-3 px-lg-5'>
            <p className='fw-bold'>Contact</p>
            <div className='mb-2' style={{ fontSize: '17px' }}>
              <div className='mb-1'><a title='contact' className='text-white' href='tel:+8444296575'>(844)429-6575</a></div>
              <a href='mailto:era@nifa.org'>era@nifa.org</a>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-3 py-0 py-lg-3'>
          <div className='pt-3 pb-3 pb-lg-0 px-3 px-lg-5'>
            <p className='fw-bold'>Social Media</p>
            <a className='mx-2' title='facebook' style={{ fontSize: '25px' }} href='https://www.facebook.com/NIFAHousing' target='_blank' rel='noopener noreferrer'><span className='fab fa-facebook' style={{ minWidth: '15px' }} /></a>
            <a className='mx-2' title='instagram' style={{ fontSize: '25px' }} href='https://www.instagram.com/nifa.housing/' target='_blank' rel='noopener noreferrer'><span className='fab fa-instagram' style={{ minWidth: '15px' }} /></a>
          </div>
        </div>
        <div className='col-12 col-md-3 py-0 py-lg-3'>
          <div className='pt-3 pb-3 pb-lg-0 px-3 px-lg-5'>
            <div>
              <img src='../images/eho.png' style={{ width: '100px' }} alt='equal housing logo' />
            </div>
          </div>
        </div>
        <div className='col-12 col-md-3 py-0 py-lg-3 pe-2'>
          <div className='pt-3 pb-3 pb-lg-0 px-3 px-lg-5'>
            <div className='mb-3'>
              <a title='privacy policy' className='fw-bold text-decoration-underline pointer' href={templateDocuments.PRVC} target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
            </div>
            <p className='mb-0' style={{ fontSize: '17px' }}>
              Nebraska ERA strives to provide online resources in a safe, secure manner that respects your privacy when you visit our site.
            </p>
            <GoogleTranslate />
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
};

export default Footer;
