import React from 'react';
import PropTypes from 'prop-types';

const Asterisk = ({ variant = 'danger' }) => <span className={`fa-solid fa-asterisk text-${variant} mx-1`} style={{ fontSize: '.6rem' }} />;

Asterisk.propTypes = {
  variant: PropTypes.string,
};

export default Asterisk;
